import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { LinksJuiceProps, LinksJuiceFlexModuleResult } from "./typings";
import { CrossLinksExpando } from "@shared-ui/retail-cross-links-expando";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { EgClickstreamData } from "@shared-ui/clickstream-analytics-context";

const impressionData: EgClickstreamData = {
  event: {
    event_name: "links_juice_component.presented",
    event_type: "Impression",
    event_version: "1.0.0",
    event_category: "",
  },
  experience: {
    page_name: "Destination Landing",
  },
};
export const LinksJuice = withStores(
  "flexModuleModelStore",
  "context",
  "uriContext"
)(
  observer((props: LinksJuiceProps) => {
    const { context, templateComponent, flexModuleModelStore, uriContext: uriContextStore } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as LinksJuiceFlexModuleResult | null;
    const uriContext = uriContextStore?.get();

    if (!model || !uriContext) {
      return null;
    }
    const { limit, strategy } = model;

    const inputs = {
      strategyName: strategy,
      uriContext: uriContext as React.ComponentProps<typeof CrossLinksExpando>["inputs"]["uriContext"],
      parameters: [],
      limit: limit || 6,
    };

    return (
      <section>
        <LazyLoad context={context}>
          <CrossLinksExpando enableCache inputs={inputs} impressionData={impressionData} />
        </LazyLoad>
      </section>
    );
  })
);

export default LinksJuice;
